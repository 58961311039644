import classNames from 'classnames';
import React from 'react';
import { NamedLink } from '../../../components';

import css from './SellerSearchCard.module.css';

function SellerCard(props) {
  const { shop } = props;

  const username = shop?.username;
  const userId = shop?.id?.uuid;
  const profileImage = shop?.profileImage;
  const displayName = shop?.displayName;
  const storeName = shop?.storename;

  const splittedName = displayName ? displayName.split(' ') : [];
  const firstNameAbb = splittedName && splittedName[0];
  const secondNameAbb = splittedName && splittedName[1];
  const abbreviatedName = (firstNameAbb && firstNameAbb[0]) + (secondNameAbb || '');
  const hasProfileImage = profileImage?.length;

  return (
    <div className={css.sellerCard}>
      {username ? (
        <NamedLink
          className={css.profileLink}
          name={username ? 'StorePage' : 'ProfilePage'}
          params={username ? { username: username || userId } : { id: userId }}
          to={{ state: { userId } }}
        >
          <div
            className={classNames({
              [css.sellerImg]: hasProfileImage,
              [css.sellerInitials]: !hasProfileImage,
            })}
          >
            {hasProfileImage ? (
              <img src={profileImage} alt={displayName} />
            ) : (
              <span className={css.initials}>{abbreviatedName}</span>
            )}
          </div>
          <div
            className={classNames(css.sellerName, {
              [css.sellerNameInit]: !hasProfileImage,
            })}
          >
            {storeName || displayName}
          </div>
          <div
            className={classNames(css.sellerinfo, {
              [css.sellerInfoInit]: !hasProfileImage,
            })}
          >
            @{username}
          </div>
        </NamedLink>
      ) : null}
    </div>
  );
}

export default SellerCard;
