import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import { injectIntl } from '../../util/reactIntl';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import facebookImage from '../../assets/sneakertimeFacebook-1200x630.png';
import twitterImage from '../../assets/sneakertimeTwitter-600x314.png';
import { withViewport } from '../../util/contextHelpers';
import { isMobile } from 'react-device-detect';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { isArrayLength } from '../../util/dataExtractors';
import SellerSearchCard from './SellerSearchCard/SellerSearchCard';
import SellerSearchCardSkeleton from './SellerSearchCard/SellerSearchCardSkeleton';

import css from './SellerSearchPage.module.css';

export const SellerSearchPageComponent = props => {
  const { intl, scrollingDisabled, searchInProgress, sellersData, searchSellersError } = props;

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'SellerSearchPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'SellerSearchPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  console.log(sellersData);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.shopSmallContainer}>
            <div className={css.fixWidthContainer}>
              {searchInProgress && !isMobile ? (
                <div className={css.spinners}>
                  <SellerSearchCardSkeleton />
                </div>
              ) : null}
              <div className={css.sellerCards}>
                {isArrayLength(sellersData)
                  ? sellersData?.map(item => <SellerSearchCard key={item?.id?.uuid} shop={item} />)
                  : null}
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { searchInProgress, sellersData, searchSellersError } = state.SellerSearchPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    sellersData,
    searchSellersError,
  };
};

const SellerSearchPage = compose(
  withRouter,
  connect(mapStateToProps, null),
  injectIntl,
  withViewport
)(SellerSearchPageComponent);

export default SellerSearchPage;
